import Cardone from "../src/assets/png/cardone.png";
import Cardtwo from "../src/assets/png/cardtwo.png";
import Cardthree from "../src/assets/png/cardthree.png";
import Cardfour from "../src/assets/png/cardfour.png";
import Sample1 from "../src/assets/png/sample1.png";
import Sample2 from "../src/assets/png/sample2.png";
import Sample3 from "../src/assets/png/sample3.png";
import Sample4 from "../src/assets/png/sample4.png";
import Sample5 from "../src/assets/png/sample5.png";
import Sample6 from "../src/assets/png/sample6.png";
import Sample7 from "../src/assets/png/sample7.png";
import Sample8 from "../src/assets/png/sample8.png";
import Sec1 from "../src/assets/png/sec1.png";
import Sec2 from "../src/assets/png/sec2.png";
import Sec3 from "../src/assets/png/sec3.png";
import Sec4 from "../src/assets/png/sec4.png";
import Sec5 from "../src/assets/png/sec5.png";
import Sec6 from "../src/assets/png/sec6.png";
import Sec7 from "../src/assets/png/sec7.png";
import Sec8 from "../src/assets/png/sec8.png";
import Sec9 from "../src/assets/png/sec9.png";
import Sec10 from "../src/assets/png/sec10.png";
import Sec11 from "../src/assets/png/sec11.png";
import Sec12 from "../src/assets/png/sec12.png";
import Sec13 from "../src/assets/png/sec13.png";
import Sec14 from "../src/assets/png/sec14.png";
import Sec15 from "../src/assets/png/sec15.png";
import Sec16 from "../src/assets/png/sec16.png";
import Sec17 from "../src/assets/png/sec17.png";
import Sec18 from "../src/assets/png/sec18.png";
import Sec19 from "../src/assets/png/sec19.png";
import Sec20 from "../src/assets/png/sec20.png";
import Hvc from "../src/assets/png/hvc.png";
import Mec from "../src/assets/png/mecc.png";

export const HOME_CARDS_MAPPING = [ 
  {
    img: Cardone,
    title: "Cost Estimating Services",
    para: "Cost Estimation is a crucial and beneficial method to understand a project's requirements and duration, though it might be quite tedious and time-intensive.",
    link: "/cost-estimating-services/",
  },
  {
    img: Cardtwo,
    title: "Construction Takeoff Services",
    para: "Our experts are always here to help you to fill the gaps which exits in the initial phases of the proposed solution especially when we talk about Quantities and Material Takeoff. ",
    link: "/construction-takeoff-services/",
  },

  {
    img: Cardthree,               
    title: "CPM Scheduling",
    para: "Scheduling transforms a project's high-level plan into a time-focused schedule, considering task durations, priorities, dependencies, resource availability, and deadlines.",
    link: "/cpm-scheduling/",
  },
  {
    img: Cardfour,
    title: "Planning",
    para: "In project management, planning establishes the project's roadmap, while scheduling aligns that roadmap with a timeline for timely execution.",
    link: "/planning/",
  },
];

export const SAMPLE_IMAGES = [
  {
    img: Sample1,
  },
  {
    img: Sample2,
  },
  {
    img: Sample3,
  },
  {
    img: Sample4,
  },
  {
    img: Sample5,
  },

  {
    img: Sample6,
  },
  {
    img: Sample7,
  },
  {
    img: Sample8,
  },
];

export const TRADE_CARDS = [
  {
    img: Sec1,
    heading: "General Requirements",
    link: "/general-requirements/"
  },
  {
    img: Sec2,
    heading: "Sitework Estimating",
    link: "/sitework-estimating-services/"
  },
  {
    img: Sec3,
    heading: "Concrete Estimating",
    link: "/concrete-estimating-services/"
  },
  {
    img: Sec4,
    heading: "Masonry Estimating",
    link: "/masonry-estimating-services/"
  },
  {
    img: Sec5,
    heading: "Metals Estimating",
    link: "/metals-estimating-services/"
  },
  {
    img: Sec6,
    heading: "Lumber Takeoff",
    link: "/lumber-takeoff-services/"
    
  },
  {
    img: Sec7,
    heading: "Thermal and Moisture Protection",
    link: "/thermal-moisture-protection-estimating-services/"

  },
  {
    img: Sec8,
    heading: "Framing Estimating",
    link: "/framing-estimators/"

  },
  {
    img: Sec9,
    heading: "Finishes Estimating",
    link: "/interior-exterior-finishes/"

  },
  {
    img: Sec10,
    heading: "Specialties",
    link: "/specialties/"

  },
  {
    img: Sec11,
    heading: "Equipment Estimating",
    link: "/equipment-estimating-services/"
  },
  {
    img: Sec12,
    heading: "Furnishing Estimating",
    link: "/furnishing-estimating-services/"

  },
];


export const TRADE_CARDS_2 = [
  {
    img: Sec13,
    heading: "Special Construction",
    link: "/special-construction-estimating-services/"
  },
  {
    img: Sec14,
    heading: "Coveying Systems",
    link: "/conveying-system/"
  },
  {
    img: Mec,
    heading: "Mechanical Estimating Services",
    link: "/mechanical-estimating-services/"
  },
  {
    img: Sec15,
    heading: "Plumbing Estimating Services",
    link: "/plumbing-estimating-services/"
  },
  
  {
    img: Sec16,
    heading: "Electrical",
    link: "/electrical-estimating-services/"
  },
  {
    img: Sec17,
    heading: "Gutter Estimating",
    link: "/gutter-estimating-services/"
  },
  {
    img: Hvc,
    heading: "HVAC",
    link: "/hvac-estimating-services/"
  },
  
  {
    img: Sec18,
    heading: "Commercial Estimating",
    link: "/commercial-estimating-services/"
  },
  {
    img: Sec19,
    heading: "Industrial Estimating",
    link: "/industrial-estimating-services/"
  },
  {
    img: Sec20,
    heading: "Residential Estimating",
    link: "/residential-estimating-services/"
  },
  
];

export const SCHEDULE_CARDS = [
  {
    head:"Primavera Calendars",
    para:"Calendars enable you to set workdays and hours, defining time availability for activities and resources. There are three types available:",
   list:"Global Calendar",
   listt:"Project Calendar",
   listtt:"Resource Calendar",
  },
  {
    head:"Activities",
    para:"Activities are tasks segmented into smaller units for resource assignment. Our expert team offers the following activity services for your construction project:",
    list:"Activity details",
    listt:"Activity usage profile",
    listtt:"Activity usage spreadsheet",
    listttt:"Resource usage spreadsheet",
  },
  {
    head:"Activity Relationships ",
    para:"Following activities, we have relationships that establish connections between project tasks, determining their sequence. In Primavera P6, relationships are categorized as:",
    list:"Finish to Start (FS)",
    listt:"Start to Start (SS)",
    listtt:"Finish to Finish (FF)",
    listttt:"Start to Finish (SF))",
  },
  
]
export const SCHEDULE_CARDSs = [
  {
    head:"Primavera Calendars",
    para:"Calendars allow you to specify available workdays and work hours on a given day. Calendars can also be allocated to each resource and activity in order to define time limitations consistently. For this three types of calendars available:"
   
  },
  {
    head:"Activities",
    para:"Set up and broken down into smaller chunks of work to which the scheduler will assign resources lateron.  We have an expert team to provide the following activities with Activity Code for your construction project."
   
  },
  
  
]